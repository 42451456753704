import Service from '@ember/service';
import Evented from '@ember/object/evented';
// import { dedupeTracked } from 'tracked-toolbox';
import { tracked } from '@glimmer/tracking';
import { task, rawTimeout } from 'ember-concurrency';
import { add } from 'date-fns';
import jstz from 'jstz';
import { isTesting, macroCondition } from '@embroider/macros';

export default class ClockService extends Service.extend(Evented) {
  @tracked interval = 1000;
  @tracked second = 0;
  @tracked minute = 0;
  @tracked five = 0;
  @tracked quarter = 0;
  @tracked hour = 0;
  @tracked time;
  @tracked date;
  offset = 0;

  constructor() {
    super();
    this.start();
  }

  get isFastBoot() {
    return this.fastboot && this.fastboot.isFastBoot;
  }

  get currentTimeZone() {
    return jstz.determine().name();
  }

  @task({ keepLatest: true })
  *loop() {
    if (macroCondition(isTesting())) {
      yield this.tick();
    } else {
      while (true) {
        try {
          yield this.tick();
          yield rawTimeout(this.interval);
        } catch (e) {
          this.reset();
        }
      }
    }
  }

  start() {
    this.loop.perform();
  }

  stop() {
    this.loop.cancelAll();
  }

  reset() {
    this.stop();
    this.second = 0;
    this.minute = 0;
    this.five = 0;
    this.quarter = 0;
    this.hour = 0;
    this.start();
  }

  tick() {
    let date = add(new Date(), { seconds: this.offset });
    let time = date.getTime();

    let changeSecond = false;
    let changeMinute = false;
    let changeHour = false;

    // we're changing system times, fire everything
    if (this.time && Math.abs(this.time - time) / 1000 > 1) {
      changeSecond = true;
    }
    if (this.time && Math.abs(this.time - time) / 1000 > 60) {
      changeMinute = true;
    }
    if (this.time && Math.abs(this.time - time) / 1000 > 3600) {
      changeHour = true;
    }

    this.time = time;
    this.date = date;

    let seconds = date.getSeconds();
    let minutes = date.getMinutes();
    let hours = date.getHours();
    this.trigger('tick', { date, time });

    if (this.second !== seconds || changeSecond) {
      this.second = seconds;
      this.trigger('tick:second', { date, time });
    }

    if (this.minute !== minutes || changeMinute) {
      this.minute = minutes;
      this.trigger('tick:minute', { date, time });
    }

    if (this.hour !== hours || changeHour) {
      this.hour = hours;
      this.trigger('tick:hour', { date, time });
    }
  }
}
