import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class PlayerNoteInfoComponent extends Component {
  @action
  checkOverflow(e) {
    let element = e.target ? e.target : e;

    if (element.scrollWidth > element.clientWidth) {
      element.classList.add('has-overflow');
    } else {
      element.classList.remove('has-overflow');
    }
  }
}
