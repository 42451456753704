import { urlNormalize } from 'url-normalize';
import isEmail from './is-email';
import isPhoneNumber from './is-phone';

function formatPhoneLink(url) {
  return url.match(/^tel:/) ? url : `tel:${url}`;
}

function formatMailTo(url) {
  return url.match(/^mailto:/) ? url : `mailto:${url}`;
}

export default function normalizeUrl(url, named = {}) {
  if (isPhoneNumber(url)) {
    return formatPhoneLink(url);
  } else if (isEmail(url)) {
    return formatMailTo(url);
  } else {
    return urlNormalize(url, { stripWWW: false, protocol: true });
  }
}
