import Helper from '@ember/component/helper';
import { tracked } from '@glimmer/tracking';
import { isBlank } from '@ember/utils';

export default class CSSProperty extends Helper {
  propertyName = null;
  attributeName = null;
  element = null;
  @tracked value;

  constructor() {
    super(...arguments);
  }

  updateProperty() {
    if (this.attributeName) {
      this.value = this.element.getAttribute(this.attributeName);
    }

    if (this.propertyName) {
      this.value = this.element.style.getPropertyValue(this.propertyName);
      if (isBlank(this.value)) {
        this.value = window
          .getComputedStyle(this.element)
          .getPropertyValue(this.propertyName);
      }
    }
  }

  compute([element], options = {}) {
    this.propertyName = options?.property;
    this.attributeName = options?.attribute;
    this.element = element;

    if (this.element) {
      this.observer = new MutationObserver(() => this.updateProperty());

      this.observer.observe(this.element, {
        attributes: true,
      });
      this.updateProperty();
    }

    return this.value;
  }
}
