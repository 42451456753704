export default function isEmail(url) {
  if (url) {
    if (url.match(/^mailto:/)) {
      return true;
    } else if (
      !url.match(/^http/) &&
      url.match(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/)
    ) {
      return true;
    } else {
      return false;
    }
  }
}