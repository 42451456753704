import { helper } from '@ember/component/helper';
import { casualTime } from './casual-time';

export default helper(function casualTimeRange(params, hash) {
  let [startTime, endTime] = params;
  let { timeZone, locale = 'en-US' } = hash;

  return `${casualTime([startTime], {
    timeZone,
    locale,
    spaceBeforeOrdinal: false,
  })}—${casualTime([endTime], { timeZone, locale, spaceBeforeOrdinal: false })}`;
});
