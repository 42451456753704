import currentTimeZone from './current-time-zone';

export default function setOptionDefaults(options) {
  if (!options) {
    options = {};
  }

  if (!options.timeZone) {
    options.timeZone = currentTimeZone();
  }
  return options;
}
