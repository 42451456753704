import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class PlayerControlComponent extends Component {
  @service stereo;

  @action
  async onTogglePlay() {
    var identifiers = [this.args.identifier];
    if (this.args.identifier?.startsWith('https')) {
      identifiers = [
        this.args.identifier,
        this.args.identifier.replace('https', 'http'),
      ];
    }

    try {
      this.stereo.playTask
        .perform(identifiers, {
          silenceErrors: false,
          useConnections: this.args.useConnections,
        })
        .catch((e) => {});
    } catch (e) {}
  }
}
