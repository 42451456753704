export function seekPositionFromActualPosition(soundPosition, gaps) {
  let adjustedPosition = soundPosition;
  (gaps || []).forEach((gap) => {
    if (gap.start <= soundPosition && gap.end >= soundPosition) {
      adjustedPosition = adjustedPosition - (soundPosition - gap.start) + 1;
    } else if (gap.end < soundPosition) {
      adjustedPosition = adjustedPosition - (gap.end - gap.start);
    }
  });

  return adjustedPosition;
}

export function actualPositionFromSeekPosition(soundPosition, gaps) {
  let adjustedPosition = soundPosition;
  (gaps || []).forEach((gap) => {
    if (gap.start <= adjustedPosition && gap.end >= adjustedPosition) {
      adjustedPosition = adjustedPosition + (gap.end - gap.start);
    } else if (gap.end <= adjustedPosition) {
      adjustedPosition = adjustedPosition + (gap.end - gap.start);
    }
  });

  return adjustedPosition;
}


export default { seekPositionFromActualPosition, actualPositionFromSeekPosition }