import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import formatToTimeZone from '../utils/format-to-time-zone';
import normalizeSpaces from '@dubbletrack/toolbox/utils/normalize-spaces';

export function casualTime(params, hash = {}) {
  let [date] = params;
  let {
    timeZone,
    locale = 'en-US',
    keepZeroes = false,
    spaceBeforeOrdinal = false,
  } = hash;
  let formattedDate;

  if (!date) {
    return '';
  } else {
    formattedDate = formatToTimeZone(date, {
      timeZone,
      locale,
      hour: 'numeric',
      minute: 'numeric',
    });
  }

  if (formattedDate.match(/:00[\s.\-a-zA-Z]+$/)) {
    if (!keepZeroes) {
      formattedDate = formattedDate.replace(/:00\s?/, '');
    }
  } else if (formattedDate.match(/:\d\d[\sa-zA-Z]+$/)) {
    formattedDate = formattedDate.replace(/(:\d\d)\s?/, '$1');
  }

  formattedDate = formattedDate.replace(/([apAP])\.?([mM]).?$/, '$1$2');

  if (spaceBeforeOrdinal) {
    formattedDate = formattedDate.replace(/\s*([apmAPM]+)/, ' $1');
  } else {
    formattedDate = formattedDate.replace(/\s?([apmAPM]+)/, '$1');
  }

  return normalizeSpaces(formattedDate)?.toLowerCase();
}

export default class CasualTime extends Helper {
  @service clock;

  compute(params, hash = {}) {
    return casualTime(params, {
      timeZone: this.clock.currentTimeZone,
      ...hash,
    });
  }
}
