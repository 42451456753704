import Component from '@glimmer/component';
import { sub } from 'date-fns';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task, timeout, waitForProperty, didCancel } from 'ember-concurrency';
import normalizeIdentifier from 'ember-stereo/-private/utils/normalize-identifier';
export default class PlayerComponent extends Component {
  @service stereo;
  @service clock;
  @service intl;
  @service router;
  @service playback;
  @service store;

  @tracked expanded = false;
  @tracked openPlaylist = this.args.openPlaylist;

  constructor() {
    super(...arguments);
    this.stereo.on('audio-played', this.playHandler.bind(this));

    if (this.args.openPlaylist) {
      this.loadTracks
        .perform()
        .then(() => (this.openPlaylist = true))
        .catch((e) => {
          if (!didCancel(e)) {
            throw e;
          }
        });
    }
  }

  playHandler({ sound }) {
    if (sound.hasUrl(this.args.identifier)) {
      this.loadTracks.perform();
    }
  }

  get loadedSound() {
    return this.stereo.findSound(this.args.identifier);
  }

  get loadedMetadata() {
    if (this.args.identifier) {
      return this.stereo.metadataCache.find(this.args.identifier);
    } else {
      return {};
    }
  }

  get items() {
    let allTracks = this.store.peekAll('item').map((i) => i.itemable);

    if (allTracks.length == 0) {
      return [];
    }

    if (this.args.isLive) {
      return allTracks
        ?.filter((t) => {
          return (
            (t.playedAt < new Date() &&
              t.playedAt > sub(new Date(), { hours: 1 })) ||
            (t.belongsTo('airing') &&
              t.belongsTo('airing').id() === this.airingId)
          );
        })
        ?.sortBy('playedAt')
        ?.reverse();
    } else {
      return allTracks?.filter((t) => {
        return (
          t.belongsTo('airing') && t.belongsTo('airing').id() === this.airingId
        );
      });
    }
  }

  get airingId() {
    return this.args.airing?.id;
  }

  get stationId() {
    if (this.args.airing) {
      return this.args.airing.belongsTo('station').id();
    } else {
      return null;
    }
  }

  get station() {
    if (this.stationId) {
      return this.store.peekRecord('station', this.stationId);
    } else {
      return null;
    }
  }

  get duration() {
    return new Date(this.endsAt).getTime() - new Date(this.startsAt).getTime();
  }

  get airingHasPast() {
    return new Date() > this.args.airing?.endsAt;
  }

  get shouldLoadTracks() {
    if (this.airingHasPast && this.lastLoaded) {
      return false;
    }
    if (!this.args.airing) {
      return false;
    }

    return true;
  }

  get normalizedIdentifier() {
    if (this.args.identifier !== '') {
      return normalizeIdentifier(this.args.identifier);
    } else {
      return this.args.identifier;
    }
  }

  @task
  *autoPlay() {
    yield timeout(1000);
    yield this.stereo.playTask.perform(this.args.identifier, {
      useConnections: this.useConnections,
    });
  }

  @task({ drop: true })
  *loadTracks() {
    yield waitForProperty(this, 'args.airing', (d) => !!d);

    if (this.shouldLoadTracks) {
      this.store.queryRecord('airing', {
        id: this.args.airing.id,
        include: ['items', 'queued_items'].join(','),
      });
      this.lastLoaded = new Date();
    }
  }

  @action togglePlaylist() {
    this.loadTracks.perform();

    this.openPlaylist = !this.openPlaylist;
  }
}
