export default function durationFromString(_duration) {
  if (typeof _duration === 'string') {
    let increments = _duration.match(/\d+[hmdws]/g);
    let duration = {};

    if (increments) {
      increments.forEach((increment) => {
        if (increment.endsWith('h')) {
          duration.hours =
            (duration.hours || 0) + parseInt(increment.slice(0, -1), 10);
        } else if (increment.endsWith('m')) {
          duration.minutes =
            (duration.minutes || 0) + parseInt(increment.slice(0, -1), 10);
        } else if (increment.endsWith('s')) {
          duration.seconds =
            (duration.seconds || 0) + parseInt(increment.slice(0, -1), 10);
        } else if (increment.endsWith('d')) {
          duration.days =
            (duration.days || 0) + parseInt(increment.slice(0, -1), 10);
        } else if (increment.endsWith('w')) {
          duration.days =
            (duration.days || 0) + parseInt(increment.slice(0, -1), 10) * 7;
        }
      });

      return duration;
    } else if (_duration.match(/\d+(?:)/gs)) {
      let increments = _duration.match(/\d+(?:)/gs);

      let seconds = increments.pop();
      let minutes = increments.pop();
      let hours = increments.pop();

      let duration = {};
      if (hours) {
        duration.hours = parseInt(hours, 10);
      }
      if (minutes) {
        duration.minutes = parseInt(minutes, 10);
      }
      if (seconds) {
        duration.seconds = parseInt(seconds, 10);
      }

      return duration;
    }
  }

  return _duration;
}
