import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import { numericDuration } from 'ember-stereo/helpers/numeric-duration';
export default class PlayerPositionComponent extends Component {
  @service stereo;
  @service clock;
  @tracked timeFormat = 'time';

  get timeElapsed() {
    if (this.args.isLive) {
      let elapsed =
        this.clock.date.getTime() - new Date(this.args.startsAt).getTime();
      return numericDuration([elapsed]);
    } else if (this.loadedSound) {
      return numericDuration([this.args.loadedSound.position]);
    } else {
      return 0;
    }
  }

  get timeRemaining() {
    if (this.args.isLive) {
      let remaining =
        new Date(this.args.endsAt).getTime() - this.clock.date.getTime();
      return numericDuration([remaining]);
    } else if (this.loadedSound) {
      let remaining = this.loadedSound.duration - this.loadedSound.position;
      return numericDuration([remaining]);
    } else {
      return 0;
    }
  }

  get loadedSound() {
    return this.stereo.findSound(this.args.identifier);
  }

  get loadedMetadata() {
    if (this.args.identifier) {
      return this.stereo.metadataCache.find(this.args.identifier);
    } else {
      return {};
    }
  }

  get loadedPercentageStyle() {
    let width = 100;
    if (
      this.clock.date >= this.args.startsAt &&
      this.clock.date <= this.args.endsAt
    ) {
      let startsAt = new Date(this.args.startsAt);
      let endsAt = new Date(this.args.endsAt);

      let currentPosition = this.clock.date.getTime() - startsAt.getTime();

      width = (currentPosition / (endsAt.getTime() - startsAt.getTime())) * 100;
    }

    return htmlSafe(`width: ${width}%;`);
  }

  get currentPercentageStyle() {
    var width = 0;

    if (
      this.clock.date >= this.args.startsAt &&
      this.clock.date <= this.args.endsAt &&
      this.loadedSound?.isSeekable
    ) {
      let startsAt = new Date(this.args.startsAt);
      let endsAt = new Date(this.args.endsAt);

      let currentPosition = this.clock.date.getTime() - startsAt.getTime();

      width =
        (currentPosition / (endsAt.getTime() - startsAt.getTime())) *
        (this.loadedSound?.position / this.loadedSound?.duration) *
        100;
    } else if (
      !this.loadedSound?.isSeekable &&
      this.args.isLive &&
      this.args.startsAt &&
      this.args.endsAt
    ) {
      let startsAt = new Date(this.args.startsAt);
      let endsAt = new Date(this.args.endsAt);

      let currentPosition = this.clock.date.getTime() - startsAt.getTime();

      width = (currentPosition / (endsAt.getTime() - startsAt.getTime())) * 100;
    } else if (this.loadedSound) {
      width = (this.loadedSound?.position / this.loadedSound?.duration) * 100;
    }

    return htmlSafe(`width: ${width}%;`);
  }

  @action
  handleTransition(e) {
    let url = e.target.href;
    if (this.router.rootURL === '/') {
      url = new URL(url).pathname;
    }

    if (this.router.recognize(url)) {
      e.preventDefault();
      this.router.transitionTo(url);
    }
  }

  onPositionChange(e) {
    let percentage = parseFloat(e.target.value, 10);
    this.recordingGapPercentages.forEach((pair) => {
      if (percentage >= pair[0]) {
        percentage = percentage + (pair[1] - pair[0]);
      }
    });

    this.stereo.currentSound.position =
      this.stereo.currentSound.duration * (percentage / 100);
  }
}
