import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class PlayerShowInfoComponent extends Component {
  @service router;

  @action
  handleTransition(e) {
    let url = e.target.href;
    if (this.router.rootURL === '/') {
      url = new URL(url).pathname;
    }

    if (this.router.recognize(url)) {
      e.preventDefault();
      this.router.transitionTo(url);
    }
  }
}
