import toDate from './to-date';
import normalizeSpaces from '@dubbletrack/toolbox/utils/normalize-spaces';
import formatISO from 'date-fns/formatISO';
import { format } from 'date-fns-tz';
export default function formatToTimeZone(
  dirtyDate,
  fmtStringOrOptions,
  dirtyOptionsOrNull
) {
  let dirtyOptions, fmtString;
  if (typeof fmtStringOrOptions === 'object') {
    fmtString = undefined;
    dirtyOptions = fmtStringOrOptions || {};
  } else {
    fmtString = fmtStringOrOptions;
    dirtyOptions = dirtyOptionsOrNull || {};
  }

  let { timeZone, locale, ...options } = dirtyOptions;
  var zonedDate = toDate(new Date(dirtyDate), { timeZone });

  if (fmtString == 'iso8601') {
    return formatISO(zonedDate);
  } else if (fmtString) {
    return format(zonedDate, fmtString, options);
  } else if (locale) {
    let formattedDate = new Intl.DateTimeFormat(locale, options).format(
      zonedDate
    );

    if (options.lowercase || options.lowerCase || options.lower) {
      return normalizeSpaces(formattedDate.toLowerCase());
    } else {
      return normalizeSpaces(formattedDate);
    }
  }
}
