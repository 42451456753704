import normalizeUrl from './normalize-url';
export default function prettyUrl(url) {
  let normalizedUrl = normalizeUrl(url);
  if (normalizedUrl && normalizedUrl.match(/:\/\//)) {
    return normalizedUrl.split('://')[1]
  } else if (normalizedUrl && normalizedUrl.match(/:/)) {
    return normalizedUrl.split(':')[1];
  } else {
    return url;
  }
}
