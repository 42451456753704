import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { isSameDay } from 'date-fns';
export default class isLive extends Helper {
  @service clock;

  compute([today]) {
    return isSameDay(this.clock.date, today);
  }
}
