import { helper } from '@ember/component/helper';

export function secondsToTime(duration) {
  var multiplier = 1;
  if (duration < 0) {
    multiplier = -1;
    duration = duration * -1;
  }

  var seconds = parseInt(duration % 60),
    minutes = parseInt((duration / 60) % 60),
    hours = parseInt((duration / (60 * 60)) % 24);

  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  let formatted = '';
  if (hours > 0) {
    formatted = hours + ':' + minutes + ':' + seconds;
  } else {
    formatted = minutes + ':' + seconds;
  }

  if (multiplier === -1) {
    return '-' + formatted;
  } else {
    return formatted;
  }
}

export default helper(function func([duration] /*, hash*/) {
  return secondsToTime(duration);
});
