import Modifier from 'ember-modifier';
import tinycolor from 'tinycolor2';
import extractStyleParams from '../utils/extract-style-params';
/* Deprecated */

export default class StyleEmbeddable extends Modifier {
  element = null;
  containerElement = null;

  convertToHsl(property, style) {
    if (style[property].charAt(0) === '#') {
      style[property] = style[property].slice(1);
    }

    let color = tinycolor(style[property]);

    let hsl = color.toHsl();
    this.element.style.setProperty(`--${property}-hue`, hsl['h']);
    this.element.style.setProperty(
      `--${property}-saturation`,
      parseFloat(hsl['s'], 10) * 100 + '%'
    );
    this.element.style.setProperty(
      `--${property}-lightness`,
      parseFloat(hsl['l'], 10) * 100 + '%'
    );
  }

  get calculatedStyle() {
    let style = {
      'dt-border-radius': '0.25rem',
      'dt-x-padding': '0.75rem',
      'dt-y-padding': '0.75rem',
      'dt-grid-column-gap': '0.75rem',
      'dt-font-size': '1em',
      'dt-background-color': '#333',
      'dt-text-color': '#FFF',
      'dt-button-color': '#FFF',
      'dt-button-size': '5.5rem',
      'dt-height': 'auto',
      'dt-min-height': 'calc(var(--dt-button-size) + 20px)',
    };

    try {
      let customStyle = extractStyleParams(this.containerElement);
      Object.keys(customStyle).forEach((key) => {
        if (key.match(/color/i)) {
          style[`dt-${key}`] = tinycolor(customStyle[key]).toHexString();
        } else {
          style[`dt-${key}`] = customStyle[key];
        }
      });
    } catch (e) {
      // no op
    }

    this.convertToHsl('dt-background-color', style);

    let backgroundColor = tinycolor(style[`dt-background-color`]);
    delete style[`dt-background-color`];

    // let textColor = tinycolor(style[`dt-text-color`]);

    style['dt-color-lightness-direction'] = backgroundColor.isDark() ? -1 : 1;

    return style;
  }

  convertVariables() {
    Object.keys(this.calculatedStyle).forEach((key) => {
      this.element.style.setProperty(
        `--${key}`,
        `${this.calculatedStyle[key]}`
      );
    });
  }

  modify(element, positionalArgs, { containerElementId }) {
    this.element = element;
    this.containerElement = containerElementId
      ? document.getElementById(containerElementId)
      : element;

    let MutationObserver =
      window.MutationObserver || window.WebKitMutationObserver;

    this.observer = new MutationObserver(() => this.convertVariables());
    this.observer.observe(this.containerElement, {
      attributes: true,
    });
    this.convertVariables();

    return () => {
      this.observer.disconnect();
    };
  }
}
