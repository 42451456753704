import { add } from 'date-fns';
import { casualTime } from '@dubbletrack/time/helpers/casual-time';
import binarySearch from '@dubbletrack/toolbox/utils/binary-search';
export default function bucketItems(airing, sound, debug = false) {
  var currentTime;

  const playedAtSort = (a, b) => {
    if (a.playedAt.getTime() > b.playedAt.getTime()) return 1;
    if (a.playedAt.getTime() < b.playedAt.getTime()) return -1;
  };

  if (sound.currentTime) {
    currentTime = sound.currentTime;
  } else if (sound.duration === Infinity || sound.isLive) {
    // we're streaming live
    currentTime = new Date();
  } else if (airing) {
    currentTime = add(airing.startsAt, {
      seconds: sound.position / 1000,
    });
  }

  let items = [
    ...(airing?.tracks || []),
    ...(airing?.traffic || []),
    ...(airing?.notes || []),
  ]
    .filter((item) => {
      return item.status !== 'queued';
    })
    .sort(playedAtSort);

  let index = binarySearch(items, currentTime, function (track, needle) {
    return track.playedAt - needle;
  });

  let values = {};
  let possiblyPlaying = undefined;
  let played = [];
  let unplayed = [];

  if (index > 0) {
    possiblyPlaying = items.at(index - 1);
    played = items.slice(0, index - 1);
    unplayed = items.slice(index, items.length);
  } else {
    possiblyPlaying = undefined;
    played = [];
    unplayed = items;
  }

  if (possiblyPlaying?.stoppedAt && currentTime > possiblyPlaying.stoppedAt) {
    // Sound has duration, and current time is past the duration. It's played.
    values = {
      played: [...played, possiblyPlaying],
      unplayed,
    };
  } else if (
    possiblyPlaying?.duration &&
    currentTime >
      add(possiblyPlaying.playedAt, { seconds: possiblyPlaying.duration })
  ) {
    // Sound has duration, and current time is past the duration. It's played.
    values = {
      played: [...played, possiblyPlaying],
      unplayed,
    };
  } else if (
    !possiblyPlaying?.duration &&
    possiblyPlaying?.playedAt > currentTime
  ) {
    // Sound has duration, and playedAt is after current time. It's unplayed
    values = {
      played,
      unplayed: [...unplayed],
    };
  } else {
    values = {
      played,
      unplayed,
      playing: possiblyPlaying,
    };
  }

  if (debug) {
    let curr = { playedAt: currentTime, title: '**' };

    let print = (item, status) => {
      console.log(
        `${status} ${casualTime([item.playedAt], { keepZeroes: true })}${
          item.duration
            ? ' -> ' +
              casualTime([add(item.playedAt, { seconds: item.duration })], {
                keepZeroes: true,
              })
            : ''
        } - ${item.title}`
      );
    };
    console.log('-------------');
    values.played.forEach((d) => print(d, 'P'));
    if (values.playing) {
      print(values.playing || {}, '*');
    } else {
      print(curr, '?');
    }
    values.unplayed.forEach((d) => print(d, 'U'));
    console.log('-------------');
  }

  return values;
}
