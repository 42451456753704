import formatToTimeZone from './format-to-time-zone';

export default function timeslot(startsAt, endsAt, localStationZone) {
  let stationStartTime = formatToTimeZone(startsAt, 'eee h:mmaa', {
    timeZone: localStationZone,
  }).toUpperCase();
  let stationEndTime = formatToTimeZone(endsAt, 'eee h:mmaa', {
    timeZone: localStationZone,
  }).toUpperCase();
  return `${stationStartTime}–${stationEndTime}`;
}
