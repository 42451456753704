import { helper } from '@ember/component/helper';
import formatToTimeZone from '../utils/format-to-time-zone';

/*
  Return the formatted date string in the given format.
  @method dateFormat
  @static
  @for date-fns/format-date
  @param {Date|String|Number} date the original date
  @param {String} format the string of tokens
  @return {String} the formatted date string
  @public
*/

export function formatDateFn(params, options = {}) {
  let [dirtyDate, fmtString] = params;

  if (fmtString) {
    return formatToTimeZone(dirtyDate, fmtString, options);
  } else {
    return formatToTimeZone(dirtyDate, options);
  }
}
export default helper(formatDateFn);
