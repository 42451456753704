import Helper from '@ember/component/helper';
import { task } from 'ember-concurrency';
import { service } from '@ember/service';

export default class PlaybackHelper extends Helper {
  @service stereo;
  @service playback;

  @task
  *playbackAiring() {
    let sound = this.stereo.findSound(this.airing.playbackUrl);
    if (this.time) {
      yield this.playback.playAiring.perform(this.airing, this.time);
    } else if (sound && sound.isPlaying && !this.restart) {
      sound.pause();
    } else {
      yield this.playback.playAiring.perform(this.airing);
    }
  }

  compute([airing], options = {}) {
    this.airing = airing;
    let { restart, time } = options;
    this.restart = restart;
    this.time = time;

    return this.playbackAiring;
  }
}
