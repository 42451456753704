export default function extractStyleParams(element) {
  let style = {};
  let length = element.attributes.length;

  for (var i = 0; i < length; i++) {
    let item = element.attributes.item(i);
    if (item.name.match(/data-dubbletrack-style/)) {
      let name = item.name.replace('data-dubbletrack-style-', '');
      style[name] = item.value;
    }
  }

  return style;
}
