import Helper from '@ember/component/helper';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';
import { isTesting, macroCondition } from '@embroider/macros';
export default class QuerySelector extends Helper {
  @tracked selector;
  @tracked id;
  @tracked element;

  @task({ restartable: true })
  *waitForElement() {
    let count = 0;
    if (macroCondition(isTesting())) {
      return;
    }

    while (!this.element) {
      this.findElement();

      yield timeout(25);
      count = count + 1;

      if (count > 100) {
        break;
      }
    }
  }

  findElement() {
    if (this.within) {
      this.element = this.within.querySelector(this.selector);
    } else if (this.id) {
      this.element = document.getElementById(this.id);
    } else {
      this.element = document.querySelector(this.selector);
    }
  }

  compute([selector], { id, within }) {
    if (selector && selector.tagName) {
      this.element = selector;
      return this.element;
    }

    if (id) {
      this.id = id;
      this.selector = `#${id}`;
    } else {
      this.selector = selector;
    }

    this.within = within;

    this.findElement();

    if (!this.element) {
      next(() => {
        this.waitForElement.perform();
      });
    }

    return this.element;
  }
}
