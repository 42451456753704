import { helper } from '@ember/component/helper';
import { formatISO } from 'date-fns';

export function isoDate(date, options = {}) {
  let { dateOnly } = options;

  if (!date) {
    return;
  }

  let fullISODate = formatISO(new Date(date));

  if (dateOnly) {
    return fullISODate.split('T')[0];
  }
  return fullISODate;
}

export default helper(function isoDateHelper(params, options) {
  let [date] = params;
  return isoDate(date, options);
});
