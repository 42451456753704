import recentQuarters from './recent-quarters';

export default function currentQuarter() {
  let quarters = recentQuarters();

  if (quarters.length > 0) {
    return quarters[0];
  } else {
    throw new Error('No quarters available');
  }
}
