import Helper from '@ember/component/helper';
import { service } from '@ember/service';

export default class isPast extends Helper {
  @service clock;

  compute([time]) {
    return this.clock.date > time;
  }
}
