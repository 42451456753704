import setOptionDefaults from './set-option-defaults';
import formatToTimeZone from './format-to-time-zone';

export default function isToday(utcDate, options = {}) {
  options = setOptionDefaults(options);
  return (
    formatToTimeZone(utcDate, 'yyyy-MM-dd', { timeZone: options.timeZone }) ==
    formatToTimeZone(new Date(), 'yyyy-MM-dd', { timeZone: options.timeZone })
  );
}
