export default function binarySearch(
  haystack,
  needle,
  comparison_callback = (a, b) => a - b
) {
  var position,
    startPos = 0;
  var endPos = haystack.length - 1;
  while (startPos <= endPos) {
    position = Math.floor((endPos + startPos) / 2);
    var comparison = comparison_callback(haystack[position], needle);
    if (comparison > 0) {
      endPos = position - 1;
    } else if (comparison < 0) {
      startPos = position + 1;
    } else {
      return position;
    }
  }

  return startPos;
}
