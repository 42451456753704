export const ALBUM = 'album';
export const SINGLE = 'single';
export const EP = 'ep';
export const BOOTLEG = 'bootleg';
export const UNRELEASED = 'unreleased';
export const LIVE_IN_STUDIO = 'live_in_studio';
export const SELF_RELEASED = 'self_released';
export const NOT_AVAILABLE = 'not_available';
export const LABEL = 'label';
export const PLAYED_AT = 'played_at';
export const QUEUED = 'queued';
export const PUBLISHED = 'published';

export const APPROVED = 'approved';
export const REJECTED = 'rejected';
export const PENDING = 'pending';

export const FEATURE = 'feature';
export const THEME = 'theme';
export const BACKGROUND = 'background';

export const ANY_STAFF = 'any_staff';
export const ANY_HOSTS = 'any_hosts';
export const ONLY_ME = 'only_me';

export const COMMENT_VISIBILITIES = [ANY_STAFF, ANY_HOSTS, ONLY_ME];

export const RELEASE_TYPES = [ALBUM, SINGLE, EP, UNRELEASED, LIVE_IN_STUDIO];
export const RELEASE_TYPES_SELECT_OPTIONS = [
  { name: 'Album', value: ALBUM },
  { name: 'Single', value: SINGLE },
  { name: 'EP', value: EP },
  { name: 'Live In Studio', value: LIVE_IN_STUDIO },
  { name: 'Unreleased', value: UNRELEASED },
];

export const LABEL_TYPES_SELECT_OPTIONS = [
  { name: 'Label', value: LABEL },
  { name: 'Self-Released', value: SELF_RELEASED },
  { name: 'Not Available', value: NOT_AVAILABLE },
];

export const TRACK = 'track';
export const TRAFFIC = 'traffic';
export const NOTE = 'note';

export const SUPER_ADMIN = 'super_admin';
export const STATION_ADMIN = 'station_admin';
export const STATION_MEMBER = 'station_member';
export const SHOW_HOST = 'show_host';
export const SHOW_COHOST = 'show_cohost';
export const SHOW_GUEST_HOST = 'show_guest_host';

export const NO_LINK = 'no_link';
export const LEARN_MORE = 'learn_more';

export default {
  ALBUM,
  SINGLE,
  EP,
  BOOTLEG,
  UNRELEASED,
  LIVE_IN_STUDIO,
  SELF_RELEASED,
  NOT_AVAILABLE,
  LABEL,
  PLAYED_AT,
  QUEUED,
  PUBLISHED,
  APPROVED,
  REJECTED,
  PENDING,
  FEATURE,
  THEME,
  BACKGROUND,
  ANY_STAFF,
  ANY_HOSTS,
  ONLY_ME,
  COMMENT_VISIBILITIES,
  RELEASE_TYPES,
  RELEASE_TYPES_SELECT_OPTIONS,
  LABEL_TYPES_SELECT_OPTIONS,
  TRACK,
  TRAFFIC,
  NOTE,
  SUPER_ADMIN,
  STATION_ADMIN,
  STATION_MEMBER,
  SHOW_HOST,
  SHOW_COHOST,
  SHOW_GUEST_HOST,
  NO_LINK,
  LEARN_MORE,
};