import { toZonedTime, toDate as origToDate } from 'date-fns-tz';
import setOptionDefaults from './set-option-defaults';

export default function toDate(dirtyDate, options = {}) {
  options = setOptionDefaults(options);
  let date;

  if (typeof dirtyDate === 'string' || typeof dirtyDate === 'number') {
    date = origToDate(dirtyDate, { timeZone: options.timeZone });
  } else if (dirtyDate instanceof Date) {
    date = dirtyDate;
  }

  if (options.timeZone) {
    return toZonedTime(date, options.timeZone);
  } else {
    return date;
  }
}
