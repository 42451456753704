import setOptionDefaults from './set-option-defaults';
import { toDate as origToDate } from 'date-fns-tz';
import endOfDay from './end-of-day';

export default function endOfWeek(dirtyDate, dirtyOptions) {
  if (arguments.length < 1) {
    throw new TypeError(
      '1 argument required, but only ' + arguments.length + ' present'
    );
  }

  var options = setOptionDefaults(dirtyOptions);

  var locale = options.locale;
  var localeWeekStartsOn =
    locale && locale.options && locale.options.weekStartsOn;
  var defaultWeekStartsOn =
    localeWeekStartsOn == null ? 0 : parseInt(localeWeekStartsOn, 10);
  var weekStartsOn =
    options.weekStartsOn == null
      ? defaultWeekStartsOn
      : parseInt(options.weekStartsOn, 10);

  // Test if weekStartsOn is between 0 and 6 _and_ is not NaN
  if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
    throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
  }

  var date = origToDate(dirtyDate);
  var day = date.getDay();
  var diff = (day < weekStartsOn ? -7 : 0) + 6 - (day - weekStartsOn);

  date.setDate(date.getDate() + diff);
  return endOfDay(date, options);
}
