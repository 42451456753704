import setOptionDefaults from './set-option-defaults';
import formatToTimeZone from './format-to-time-zone';
import { toDate as origToDate } from 'date-fns-tz';

export default function startOfDay(utcDate, options = {}) {
  options = setOptionDefaults(options);
  const dateString = formatToTimeZone(utcDate, 'yyyy-MM-dd', {
    timeZone: options.timeZone,
  });

  return origToDate(`${dateString}T00:00:00`, { timeZone: options.timeZone });
}
