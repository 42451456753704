import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { actualPositionFromSeekPosition } from '../utils/adjusted-position';
import { add } from 'date-fns';

export default class currentPositionAsTime extends Helper {
  @service stereo;
  @tracked identifier;
  @tracked startsAt;

  get loadedSound() {
    return this.stereo.findSound(this.identifier);
  }

  get loadedId3Data() {
    return this.loadedSound?.id3TagMetadata || {};
  }

  get position() {
    if (this.loadedSound?.currentTime) {
      return this.loadedSound.currentTime;
    } else if (this.startsAt) {
      return add(new Date(this.startsAt), {
        seconds:
          actualPositionFromSeekPosition(this.loadedSound?.position || 0) /
          1000,
      });
    } else {
      return undefined;
    }
  }

  compute([identifier], { startsAt }) {
    this.identifier = identifier;
    this.startsAt = startsAt;
    return this.position;
  }
}
