import isEmail from './is-email';
import isPhoneNumber from './is-phone';

export default function urlType(url) {
  if (isPhoneNumber(url)) {
    return 'phone';
  } else if (isEmail(url)) {
    return 'email';
  } else {
    return 'url';
  }
}
