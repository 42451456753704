export default function nowPlayingMetadata(metadata) {
  let mediaSession = {
    artist: undefined,
    title: undefined,
    album: undefined,
    artwork: undefined,
  };

  mediaSession.artwork = [
    {
      src: metadata.show?.image?.medium_square?.url,
    },
  ];

  if (metadata.itemType === 'track') {
    mediaSession.artist = metadata.track?.artistName;
    mediaSession.title = metadata.track?.title;
    mediaSession.album = metadata.track?.releaseName;
  } else if (metadata.itemType === 'note') {
    mediaSession.artist = metadata.track?.artistName;
    mediaSession.title = metadata.track?.title;
    mediaSession.album = metadata.track?.releaseName;
  } else {
    mediaSession.title = metadata.show?.title;
  }

  return mediaSession;
}
