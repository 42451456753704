export default function isPhoneNumber(url) {
  if (url) {
    if (url.match(/^tel:/)) {
      return true;
    } else if (!url.match(/^http/) && url.match(/^[0-9\-\+\.\s]+$/)) {
      return true;
    } else {
      return false;
    }
  }
}