import Modifier from 'ember-modifier';
import { service } from '@ember/service';

export default class StyleEmbeddable extends Modifier {
  @service elementRefs;

  element = null;
  containerElement = null;

  modify(element, [name]) {
    this.elementRefs.refs[name] = element;
  }
}
