import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { sub } from 'date-fns';

export default class isUpcoming extends Helper {
  @service clock;

  compute([startsAt]) {
    return (
      this.clock.date >= sub(startsAt, { minutes: 15 }) &&
      this.clock.date <= startsAt
    );
  }
}
