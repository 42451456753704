import sinon from 'sinon';
import Clock from '../services/clock';

async function freezeDateAt(date) {
  this._clock = sinon.useFakeTimers({
    now: date.getTime(),
    shouldAdvanceTime: true,
  });
}

async function unfreezeDate() {
  if (this._clock) {
    this._clock.restore();
  }
}

export async function setupTime(hooks, options = {}) {
  hooks.beforeEach(async function () {
    this.freezeDateAt = freezeDateAt;
    this.unfreezeDate = unfreezeDate;

    // this.owner.register('service:clock', ClockService);
    if (options.freezeDateAt) {
      this.freezeDateAt(options.freezeDateAt);
    }
  });

  hooks.afterEach(async function () {
    this.unfreezeDate();
  });
}

export async function setupClock(hooks, options = {}) {
  hooks.beforeEach(async function () {
    if (options.freezeDateAt) {
      this.owner.register(
        'service:clock',
        class ClockService extends Clock {
          tick() {
            this.date = options.freezeDateAt;
            this.time = options.freezeDateAt.getTime();
          }
        }
      );
      let clock = this.owner.lookup('service:clock');
      clock.start();
    }
  });

  hooks.afterEach(async function () {
    this.owner.lookup('service:clock').stop();
  });
}

export default { setupClock, setupTime };
