import Modifier from 'ember-modifier';
import { service } from '@ember/service';
import { Wave } from '@foobar404/wave';
import { task } from 'ember-concurrency';
import { later } from '@ember/runloop';

import hasEqualIdentifiers from 'ember-stereo/-private/utils/has-equal-identifiers';
import { registerDestructor } from '@ember/destroyable';
export default class StereoVisualizerModifier extends Modifier {
  @service stereo;

  element = null;
  playerRoot = null;
  identifier = null;

  constructor() {
    super(...arguments);
    registerDestructor(this, this.unregisterListeners.bind(this));

    this.setupVisualizer = async ({ sound }) => {
      let hasEqual = await hasEqualIdentifiers(this.identifier, sound.url);
      if (hasEqual) {
        later(() => {
          this.visualize.perform(sound, this.element);
        }, 200);
      }
    };

    this.stopVisualizer = async ({ sound }) => {
      let hasEqual = await hasEqualIdentifiers(this.identifier, sound.url);
      if (hasEqual) {
        later(() => {
          this.disconnect();
        }, 100);
      }
    };
  }

  modify(canvas, [identifier], { lineColor, fillColor, type }) {
    this.lineColor = lineColor;
    this.fillColor = fillColor;
    this.type = type;
    this.identifier = identifier;

    if (this.element) {
      return;
    }

    this.element = canvas;

    canvas.setAttribute(
      'id',
      'canvas_' + window.crypto.getRandomValues(new Uint32Array(1))[0]
    );

    this.registerListeners();
  }

  @task({ restartable: true })
  *visualize(sound, element) {
    if (!(this.fillColor && this.lineColor)) {
      return;
    }

    if (this.wave) {
      this.disconnect();
    }

    this.wave = new Wave(sound.audioElement, element);

    if (this.type === 'viz-bottom') {
      this.wave.addAnimation(
        new this.wave.animations.Wave({
          lineWidth: 1,
          bottom: true,
          rounded: true,
          lineColor: this.lineColor,
          fillColor: this.fillColor,
        })
      );
    } else {
      this.wave.addAnimation(
        new this.wave.animations.Wave({
          lineWidth: 1,
          center: true,
          mirroredY: true,
          mirroredX: true,
          lineColor: this.lineColor,
          fillColor: this.fillColor,
        })
      );
    }

    const event = new Event('play');
    yield this.wave._audioElement?.dispatchEvent(event);
  }

  disconnect() {
    // console.log(`disconnecting ${this.identifier}`);
    this.wave?._audioSource?.disconnect();
    this.wave?._audioContext?.close();
    this.wave?.clearAnimations();
    this.wave = null;
  }

  registerListeners() {
    this.stereo.on('current-sound-changed', ({ sound }) => {
      this.stopVisualizer({ sound });
      this.setupVisualizer({ sound });
    });
    this.stereo.on('audio-paused', this.stopVisualizer);
    this.stereo.on('audio-stopped', this.stopVisualizer);
    this.stereo.on('audio-played', this.setupVisualizer);
  }

  unregisterListeners() {
    this.stereo.off('audio-paused', this.stopVisualizer);
    this.stereo.off('audio-stopped', this.stopVisualizer);
    this.stereo.off('audio-played', this.setupVisualizer);
  }
}
