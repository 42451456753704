import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import {
  parseJSON,
  differenceInDays,
  differenceInSeconds,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
  differenceInHours,
  differenceInMinutes,
} from 'date-fns';

export default class RelativeTime extends Helper {
  @service intl;

  units = [
    {
      max: 2760000,
      value: 60000,
      name: 'minute',
      past: 'a minute ago',
      future: 'in a minute',
    },
    {
      max: 72000000,
      value: 3600000,
      name: 'hour',
      past: 'an hour ago',
      future: 'in an hour',
    },
    {
      max: 518400000,
      value: 86400000,
      name: 'day',
      past: 'yesterday',
      future: 'tomorrow',
    },
    {
      max: 2419200000,
      value: 604800000,
      name: 'week',
      past: 'last week',
      future: 'in a week',
    },
    {
      max: 28512000000,
      value: 2592000000,
      name: 'month',
      past: 'last month',
      future: 'in a month',
    }, // max: 11 months
  ];

  format(diff, divisor, unit, past, future, isInTheFuture) {
    var val = Math.round(Math.abs(diff) / divisor);
    if (isInTheFuture)
      return val <= 1 ? future : 'in ' + val + ' ' + unit + 's';
    return val <= 1 ? past : val + ' ' + unit + 's ago';
  }

  compute([date, max]) {
    if (date) {
      if (typeof date.getTime == 'function') {
        date = date;
      } else {
        date = parseJSON(date);
      }

      var diff = Date.now() - date.getTime();
      // less than a minute
      if (Math.abs(diff) < 60000) return 'just now';
      for (var i = 0; i < this.units.length; i++) {
        if (
          Math.abs(diff) < this.units[i].max ||
          (max && this.units[i].name === max)
        ) {
          return this.format(
            diff,
            this.units[i].value,
            this.units[i].name,
            this.units[i].past,
            this.units[i].future,
            diff < 0
          );
        }
      }
      // `year` is the final unit.
      // same as:
      //  {
      //    max: Infinity,
      //    value: 31536000000,
      //    name: 'year',
      //    past: 'last year'
      //  }
      return this.format(
        diff,
        31536000000,
        'year',
        'last year',
        'in a year',
        diff < 0
      );
    }
  }
}
