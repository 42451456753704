import { helper } from '@ember/component/helper';

export function secondsToDuration(duration) {
  var seconds = parseInt(duration % 60),
    minutes = parseInt((duration / 60) % 60),
    hours = parseInt((duration / (60 * 60)) % 24);

  if (hours > 0) {
    return hours + 'h ' + minutes + 'm ' + (seconds > 0 ? seconds + 's' : '');
  } else if (minutes > 0) {
    return minutes + 'm ' + seconds + 's';
  } else {
    return seconds + 's';
  }
}

export default helper(function func([duration] /*, hash*/) {
  return secondsToDuration(duration);
});
