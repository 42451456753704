import Service from '@ember/service';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import nowPlayingMetadata from '../utils/now-playing-metadata';
import EmberEvented from '@ember/object/evented';
import { getOwner, setOwner } from '@ember/application';
import StreamMonitor from '../utils/stream-monitor';
import { TrackedArray } from 'tracked-built-ins';
import { assert } from '@ember/debug';

export default class NowPlayingService extends Service.extend(EmberEvented) {
  @service stereo;
  @service cable;
  @service store;
  @service clock;
  @service router;

  @tracked monitors = new TrackedArray();

  @task
  *watch({ stationUid, stationId, streamId, cableUrl }) {
    let owner = getOwner(this);

    let monitor = this.monitors.find((mon) => {
      return mon.streamId === streamId && mon.stationId === stationId;
    });

    if (monitor) {
      if (!monitor.start.isRunning) {
        yield monitor.start.perform();
      }
    } else {
      monitor = new StreamMonitor({
        stationUid,
        stationId,
        streamId,
        cableUrl,
        onUpdate: (url, recordMap) => {
          this.updateAudioMetadata.perform(url, recordMap);
        },
      });
      setOwner(monitor, owner);
      this.monitors.push(monitor);
      yield monitor.start.perform();
    }

    return { streamUrl: monitor.streamUrl, monitor };
  }

  @task
  *updateAudioMetadata(url, recordMap) {
    let currentMetadata = this.stereo.metadataCache.find(url);
    let {
      nowPlaying,
      airing,
      item,
      show,
      track,
      note,
      traffic,
      station,
      broadcast,
    } = recordMap;

    if (nowPlaying) {
      broadcast = nowPlaying.broadcast;
      station = airing?.station || broadcast?.station || station;
      airing = this.store.peekRecord('airing', nowPlaying.id);
      if (nowPlaying.items) {
        item = nowPlaying.items?.[0]?.item;
      } else {
        track = nowPlaying.tracks?.[0];
        note = nowPlaying.notes?.[0];
        traffic = nowPlaying.traffic?.[0];

        item = [track, note, traffic]
          .filter((i) => !!i)
          .sort((a, b) => {
            return a.playedAt < b.playedAt ? 1 : -1;
          })[0];
      }
    }

    // select most recent item

    let objects = {
      airing,
      show: show || airing?.show,
      station: station || airing?.station,
      broadcast: broadcast || airing?.broadcast,
      item: item,
      note: undefined,
      track: undefined,
      traffic: undefined,
      itemType: item?.type,
    };

    if (item) {
      assert('item must have a type', item.type);
      if (item.type) {
        objects[item.type] = item;
      } else {
      }
    }

    let metadata = {
      ...currentMetadata,
      ...objects,
    };

    metadata = { ...metadata, ...nowPlayingMetadata(metadata) };

    metadata.isLive =
      metadata.airing.startsAt < this.clock.date &&
      metadata.airing.endsAt > this.clock.date;

    this.stereo.metadataCache.store(url, metadata);
    yield this.trigger('change', { url, metadata });

    return { url, metadata };
  }
}
