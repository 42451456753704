import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { add } from 'date-fns';
import { A } from '@ember/array';

export default class TrackHasPlayed extends Helper {
  @service stereo;
  @service store;

  trackEnd(track, nextTrack) {
    if (parseInt(track.duration, 10) > 0) {
      return add(track.playedAt, { seconds: track.duration });
    } else if (nextTrack) {
      return nextTrack.playedAt;
    } else {
      return false;
    }
  }

  computeValue(track, nextTrack) {
    try {
      if (
        track &&
        this.stereo.currentSound &&
        this.stereo.currentSound.metadata.airing?.id ===
          track.belongsTo('airing').id()
      ) {
        if (!nextTrack) {
          let airing = this.store.peekRecord(
            'airing',
            track.belongsTo('airing').id()
          );
          let index = airing.items.findIndex((t) => t.id == track.id);
          let nextIndex = index + 1;
          if (airing.items.length >= nextIndex) {
            nextTrack = airing.items[index + 1];
          }
        }

        let trackEnd = this.trackEnd(track, nextTrack);
        let currentTime = this.stereo.currentSound.currentTime;
        let calculatedTime =
          track.airing.startsAt.getTime() + this.stereo.currentSound.position;

        let time = Math.max(
          ...A([currentTime?.getTime(), calculatedTime]).compact()
        );

        return trackEnd && time > trackEnd.getTime();
      }

      return false;
    } catch (e) {
      // no op
    }

    return false;
  }

  compute([track, nextTrack]) {
    // let t0 = performance.now();
    let value = this.computeValue(track, nextTrack);
    // let t1 = performance.now();

    return value;
  }
}
